import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-nav">
        <a rel="noopener noreferrer" href="https://github.com/skw" target="_blank">GitHub</a>
        <span>\</span>
        <a rel="noopener noreferrer" href="https://angel.co/shaun-kirk-wong" target="_blank">Angel</a>
        <span>\</span>
        <a rel="noopener noreferrer" href="https://www.instagram.com/fooling/" target="_blank">Instagram</a>
      </div>
      <header className="App-header">
        <h1 className="App-title">
          Hello <img alt=":wave:" src="https://github.githubassets.com/images/icons/emoji/unicode/1f44b.png" /> 
        </h1>
        <p>
        My name's <strong>Shaun Kirk Wong.</strong>
        </p>
        <p>I'm currently building things <a
          className="App-link"
          href="https://github.com/skw"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>.</p>
        <p>Previously <a
          className="App-link"
          href="https://gitalytics.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gitalytics
        </a> and <a
          className="App-link"
          href="https://bench.co/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bench
        </a>.</p>
      </header>
    </div>
  );
}

export default App;
